.App {
  text-align: center;
  background-color: #e3e1e1; /* Replace with your desired color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-content {
  flex: 1;
}
