/* consign.css */

/* Custom styles for the calendar */
.react-calendar {
  border: 2px solid #3794fe; /* Change the border color */
  border-radius: 24px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow */
  padding: 32px; /* Increase padding for more space */
  background-color: #f5f5f5; /* Background color */
  display: block; /* Make the calendar block-level to allow centering */
  margin: 0 auto; /* Center horizontally */
  width: 100%; /* Make the calendar take full width */
  max-width: 800px; /* Increase max width */
  height: auto; /* Allow height to adjust automatically */
}

/* Center the overall container */
.consign-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  padding: 40px; /* Increase padding for more space */
}

/* Flexbox layout for the calendar wrapper */
.calendar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1400px; /* Increase max width */
  margin: 0 auto; /* Center the wrapper in the container */
}

/* Styling for each individual calendar container */
.calendar-container {
  width: 100%;
  max-width: 700px; /* Increase calendar width */
  margin-bottom: 40px; /* Increase space between calendars */
  text-align: center; /* Centers the titles */
}

/* Responsiveness: On larger screens, show calendars side by side */
@media (min-width: 768px) {
  .calendar-wrapper {
    flex-direction: row; /* Stack the calendars side by side */
    justify-content: center; /* Center the calendars horizontally */
  }

  .calendar-container {
    width: 48%; /* Makes each calendar 48% of the container width */
    margin-bottom: 0; /* Remove margin for side-by-side layout */
  }
}

/* Navigation button styling */
.react-calendar__navigation button {
  color: #3794fe; /* Change the navigation buttons color */
}

.react-calendar__month-view__days__day--weekend {
  color: #3794fe; /* Change the color of weekend days */
}

.react-calendar__tile--active {
  background-color: grey !important; /* Active day background color */
  color: white !important; /* Active day text color */
}

.react-calendar__tile--now {
  background: #f5f5f5 !important;
  color: black !important;
}

.react-calendar__tile--unavailable {
  background: #f5f5f5 !important; /* Default background */
  color: #3794fe; /* Common text color for unavailable days */
}

/* Specific styles for Manchester unavailable dates */
.react-calendar__tile--unavailable.manchester {
  background: #c8e066; /* Green background for Manchester */
}

/* Specific styles for Red Lion unavailable dates */
.react-calendar__tile--unavailable.redlion {
  background: #ff4d4d; /* Red background for Red Lion */
}
